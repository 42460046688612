import axios from "axios"
import { config } from "@/config"

// return the payment instrument object
export async function createSynswiPaymentInstrument(requestBody: any) {
    console.log(requestBody)
    const res = await axios.post(`${config.synswiBaseUrl}/payment-instruments`, requestBody)
    let paymentInstrument = res.data
    console.log(paymentInstrument)
    return paymentInstrument
}

export async function createFinixToken(finixTokenRequest: any) {
    console.log(finixTokenRequest)
    let res = await axios.post(
        `https://internal.${config.finixEnvironment}-payments-api.com/applications/${config.applicationId}/tokens`,
        finixTokenRequest
    )
    const token = res.data.id
    return token
}